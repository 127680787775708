import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['background']

  state = {
    tolerance: 1000,
    loadedClass: 'is-loaded',
    hasBackground: this.hasBackgroundTarget,
    backgroundSrc: this.data.get('src') || false
  }

  connect() {
    const {
      state
    } = this;
    if (state.hasBackground && state.backgroundSrc) {
      this.loadBackground();
    }
  }

  loadBackground() {
    const {
      state
    } = this;
    const image = new Image();
    image.addEventListener('load', () => {
      this.backgroundTarget.style.backgroundImage = `url(${state.backgroundSrc})`;
      setTimeout(() => {
        this.element.classList.add(state.loadedClass);
      }, state.tolerance);
    });
    image.src = state.backgroundSrc;
  }
}
